import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {pointRedStyle} from "./customStyles";
import Feature from "ol/Feature";
const offset = -180  //global offset to draw in WCG_84_R projection (what is moved left with 180 degrees

export function getSignalLayer(signal) {
  let points = []

  let features = []
  console.log('signal', signal)

  if (signal) {
    points.push(signal)



    points.forEach( p => {
      let geometruLongitude = p.longitude + offset
      if (geometruLongitude > 180) {
        geometruLongitude = geometruLongitude - 360
      } else if (geometruLongitude < -180){
        geometruLongitude = geometruLongitude + 360
      }

      let feature = new Feature({
        name: p.id,
        type: 'Feature',
        geometry: new Point([geometruLongitude, p.latitude]),
        properties: {
          type: 'signal',
          ...signal
        },
      })
      features.push(feature)
    })

  }

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointRedStyle,
    zIndex: 100,
    wrapX: true
  });
}

