<template>
  <div class="signal-popup" >
    <div class="protected-popup-row">
      <div>Виртуальный источник:</div>
    </div>
    <div class="protected-popup-row">
      <div>Название</div>
      <div>{{virtual.latitude ? virtual.name : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Широта</div>
      <div>{{getLatitude}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Долгота</div>
      <div>{{getLongitude}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Магнитуда</div>
      <div>{{virtual.magnitude}}</div>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'virtualPopup',
    props: {
      virtual: Object
    },
    computed: {
      getLongitude() {
        if (!this.virtual.longitude) return '-'
        let longitude = this.virtual.longitude > 180 ? this.virtual.longitude - 360 : this.virtual.longitude
        return Number.parseFloat(longitude.toFixed(2))
      },
      getLatitude() {
        if (!this.virtual.latitude) return '-'
        let latitude = this.virtual.latitude > 90 ? this.virtual.latitude - 360 : this.virtual.latitude
        return Number.parseFloat(latitude.toFixed(2))
      }
    }

  }
</script>

<style scoped>
  .signal-popup{
    background-color: red;
    padding: 15px;
    gap: 5px;

  }

  .protected-popup-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: white;
    font-size: 18px;
  }
</style>