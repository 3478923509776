import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {pointYellowStyle} from "./customStyles";
import Feature from "ol/Feature";

function createFetatures(points, offset) {
  let shift = +offset ? offset : 0

  let features = []
  points.forEach( p => {
    let feature = new Feature({
      name: p.name,
      type: 'Feature',
      geometry: new Point([p.longitude + shift, p.latitude]),
      properties: {
        name: p.name,
        category: 'Защищаемый пункт',
        latitude: p.latitude,
        longitude: p.longitude,
        time: p.time,
        waveTime: p.waveTime,
        waveHeight: p.waveHeight,
        minWaveTime: p.minWaveTime,
        minWaveHeight: p.minWaveHeight,
        maxWaveTime: p.maxWaveTime,
        maxWaveHeight: p.maxWaveHeight,
        region: p.region ? p.region : p.type,
      },
    })
    features.push(feature)
  })

  return features
}

function formLayer(features, zIndex) {

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointYellowStyle,
    zIndex: zIndex
  });
}


export function getProtectedPointLayerFromCalc(calcResults) {
  let points = []
  //handle points
  console.log('protected point response', calcResults)

  let features = []
  if (calcResults && calcResults.length) {
    calcResults.forEach(r => {
      console.log('r', r)
      points.push(...r.results.map( res => {return {...res, region: r.type}}))
    })

    features = createFetatures(points, -180)

  }

  return formLayer(features, 30)
}

export function getProtectedPointLayerFromLocations(locations) {
  // console.log('protected point response from locations', locations)

  let features = createFetatures(locations, -180)
  return formLayer(features, 25)
}

