<template>
  <div>
    <div style="display: flex">
      <div style="flex-grow: 1">
        <div ref="map-root" id="map"
             style="width: 100%; height: 100%; border: 1px solid black; background-color: #ADD8E6">
          <div id="info">
            <protected-point-popup v-if="!!protectedPoint && protectedPoint.region" :key="protectedPoint.region + protectedPoint.waveTime" :protected-point="protectedPoint" :signal="signal"/>
            <gauges-popup v-if="!!selectedGauge" :key="selectedGauge.code" :gauge="selectedGauge"/>
            <signal-popup v-if="!!selectedSignal" :key="selectedSignal.id" :signal="selectedSignal"/>
            <virtual-popup v-if="!!selectedVirtual" :key="selectedVirtual.id" :virtual="selectedVirtual"/>
          </div>
        </div>
        <!--zoom {{zoom}} center {{center}} feature: {{feature}} pointer: {{pointerCoords}}-->
      </div>
      <div style="display: flex; flex-grow: 0; flex-direction: column; padding-left: 10px; justify-items: flex-start; justify-content: flex-start; gap: 10px; max-width: 300px">
        <md-button  @click="formLayers()" class="md-raised md-primary" style="" :disabled="loadingLayers">Применить</md-button>
        Отображение на карте
        <md-checkbox v-model="coastalLayerEnabled" :value="true" class="md-secondary ">Прибрежные посты (Coastal)</md-checkbox>
        <md-checkbox v-model="dartLayerEnabled" :value="true" style="margin: 0; text-align: start;  min-height: 40px;">Глубоководные датчики системы Dart</md-checkbox>
        <md-checkbox v-model="baseLayerEnabled" :value="true" >Защищаемые пункты</md-checkbox>
        <md-checkbox v-model="virtualLayerEnabled" >Виртуальные источники</md-checkbox>

        <div v-show="!signal"  style="text-align: start">Выберите землетрясение в списке</div>
        <md-checkbox v-model="signalLayerEnabled"  class="md-secondary " :disabled="!signal">Эпицентр землетрясения</md-checkbox>

        <md-checkbox v-model="isoLayerEnabled"  style="margin: 0; text-align: start; min-height: 40px; max-height:160px">Изохроны времени добегания волны цунами</md-checkbox>
        <div>Распределение высот волн цунами</div>
        <md-checkbox v-model="overlayType" value="maxGlowEnabled" style="margin: 0" :disabled="!signal">Максимальная высота волна</md-checkbox>
        <md-checkbox v-model="overlayType" value="minGlowEnabled" style="margin: 0" :disabled="!signal">Минимальная высота волна</md-checkbox>


        <div style="display: flex; gap: 10px; ">
          <div style="width: 50px;">
            <div  style="opacity: 0; height: 8px"></div>
            <div  style="background-color: #ff0000; height: 8px"></div>
            <div  style="background-color: #ff8000; height: 8px"></div>
            <div  style="background-color: #ffff00; height: 8px"></div>
            <div  style="background-color: #80ff80; height: 8px"></div>

            <div  style="background-color: #ADD8E6; height: 8px"></div>

            <div  style="background-color: #0080ff; height: 8px"></div>
            <div  style="background-color: #4b00FF; height: 8px"></div>
            <div  style="background-color: #b09eff; height: 8px"></div>
            <div  style="background-color: #B533D9; height: 8px"></div>
            <div  style="opacity: 0; height: 8px"></div>
            <!--R 181 G 51 B 217-->
          </div>
          <div style="display: flex; flex-direction: column; justify-content: space-between">
            <div> &gt;1м</div>
            <div>0</div>
            <div> &lt;-1м</div>
          </div>
        </div>


      </div>
    </div>
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content"></div>
    </div>
  </div>
</template>

<script>

  import logger from "../utils/logger";
  import {mapGetters} from "vuex";

  // importing the OpenLayers stylesheet is required for having
  import 'ol/ol.css'
  import Map from 'ol/Map.js';
  import {FullScreen, Zoom} from 'ol/control.js';
  // import {MousePosition} from 'ol/control.js';
  import View from 'ol/View.js';

  import {getProtectedPointLayerFromCalc, getProtectedPointLayerFromLocations} from "../utils/map/protectedPointsLayer";
  import ProtectedPointPopup from '../components/protectedPointPopup'
  import GaugesPopup from '../components/gaugesPopup'
  import SignalPopup from '../components/signalPopup'
  import VirtualPopup from '../components/VirtuallPopup'
  import {getDartGaugesLayer} from "../utils/map/gaugeDartLayer";
  import {getCoastalLGaugesLayer} from "../utils/map/gaugeCoastalLayer";
  import {getSignalLayer} from "../utils/map/signalLayer";
  import {getGeotiffLayerAsync} from "../utils/map/geoTiffLayerAsync";
  import {getWorldLayer} from "../utils/map/worldLayer";
  import {getLocations} from "../api/location-controller";
  import {getIsoLayer} from "../utils/map/isoLayer";
  import {getVirtualLayer} from "../utils/map/virtualLayer";

  let currentFeature;


  export default {
    name: 'MapContainer',
    components: {ProtectedPointPopup, GaugesPopup, SignalPopup, VirtualPopup},
    props: {
      signal: Object
    },
    data() {
      return {
        loadingLayers: false,
        protectedPoint: null,
        selectedGauge: null,
        selectedSignal: null,
        selectedVirtual: null,
        feature: '',
        gauge: null,

        pointerCoords: '',

        map: null,
        dartGaugesLayer: null,
        coastalGaugesLayer: null,
        basePointsLayer: null,
        calcPointsLayer: null,
        signalLayer: null,
        virtualLayer: null,

        dartLoaded: false,
        coastalLoaded: false,

        baseLayer: null,
        baseLayerEnabled: true, // protected points
        // protectedPointsEnabled: true,
        signalLayerEnabled: false,
        dartLayerEnabled: true,
        coastalLayerEnabled: true,
        virtualLayerEnabled: false,


        // maxGlowEnabled: false,
        // minGlowEnabled: false,
        isoLayerEnabled: true,

        overlayType: null,
        maxGlowingLayer: null,
        minGlowingLayer: null
      }
    },
    watch: {
      signal(newVal) {
        if (newVal) {
          try {
            this.loadingLayers = true

            if ((this.map.getLayers().getArray().some(layer => layer === this.signalLayer))) {
              this.map.removeLayer(this.signalLayer);
            }
            if ((this.map.getLayers().getArray().some(layer => layer === this.calcPointsLayer))) {
              this.map.removeLayer(this.calcPointsLayer);
            }
            if ((this.map.getLayers().getArray().some(layer => layer === this.isoLayer))) {
              this.map.removeLayer(this.isoLayer);
            }
            if ((this.map.getLayers().getArray().some(layer => layer === this.minGlowingLayer))) {
              this.map.removeLayer(this.minGlowingLayer);
            }
            if ((this.map.getLayers().getArray().some(layer => layer === this.maxGlowingLayer))) {
              this.map.removeLayer(this.maxGlowingLayer);
            }


            this.signalLayer = null
            this.isoLayer = null
            this.calcPointsLayer = null
            this.minGlowingLayer = null
            this.maxGlowingLayer = null

            // this.minGlowEnabled = false
            // this.maxGlowingLayer = false
            // this.signalLayerEnabled = false
          } catch (e) {
            console.error('error on changing signal', e)
          } finally {
            this.loadingLayers = false
          }


          // this.signalLayer = getSignalLayer(this.signal)
        } else {
          if (this.signalLayer) {
            this.signalLayer.setVisible(false)
            // delete(this.signalLayer)
          }
        }

      },
      getCalcResults(newVal) {
        if (newVal && newVal.length) {
          this.calcPointsLayer = getProtectedPointLayerFromCalc(newVal)
        }
        this.formLayers()

      },
      getGauges(newVal) {
        if (!newVal) return;
        this.buildGaugeLayers(newVal)
      }
    },
    computed: {
      ...mapGetters(['getCalcResults', 'getGauges']),
      zoom() {
        return (this.map && this.map.getView()) ? this.map.getView().getZoom() : ''
      },
      center() {
        return (this.map && this.map.getView()) ? this.map.getView().getCenter() : ''
      }
    },
    methods: {
      buildGaugeLayers(gauges) {
        let coastalGauges = gauges.filter(g => g.type === 'COASTAL')
        let dartGauges = gauges.filter(g => g.type === 'DART')
        this.dartGaugesLayer = getDartGaugesLayer(dartGauges)
        this.coastalGaugesLayer = getCoastalLGaugesLayer(coastalGauges)
        this.formLayers()
      },
      buildVirtualLayer: function() {
        try {
          if (this.virtualLayerEnabled) {
            if (!this.virtualLayer) {
              getVirtualLayer()
                .then(layer => {
                  this.virtualLayer = layer
                  this.map.addLayer(this.virtualLayer)
                })
                .catch(e => {
                  console.error('eeror loading virtual sources', e)
                })
            } else {
              this.virtualLayer.setVisible(true)
            }
          } else {
            if (this.virtualLayer) {
              this.virtualLayer.setVisible(false)
            }
          }
        } catch (e) {
          console.error('error loading virtual sources layer', e)
        }
      },
      buildBaseLayer() {
        try {
          if (this.basePointsLayer != null || this.calcPointsLayer != null) {
            if (this.baseLayerEnabled) {
              if (this.calcPointsLayer) {
                // if (this.basePointsLayer) this.map.removeLayer(this.basePointsLayer)
                this.basePointsLayer.setVisible(false)
                this.calcPointsLayer.setVisible(true)
                if (!(this.map.getLayers().getArray().some(layer => layer === this.calcPointsLayer))) {
                  this.map.addLayer(this.calcPointsLayer);
                }
              } else {
                if (this.basePointsLayer) this.basePointsLayer.setVisible(true)
                if (this.calcPointsLayer) this.calcPointsLayer.setVisible(false)
                if (!(this.map.getLayers().getArray().some(layer => layer === this.basePointsLayer))) {
                  this.map.addLayer(this.basePointsLayer);
                }
              }
            } else {
              if (this.calcPointsLayer) this.calcPointsLayer.setVisible(false)
              if (this.basePointsLayer) this.basePointsLayer.setVisible(false)
            }
          }
        } catch (e) {
          console.error('error loading protected points layer', e)
        }
      },
      buildDartLayer() {
        try {
          if (this.dartGaugesLayer != null) {
            if (this.dartLayerEnabled) {
              this.dartGaugesLayer.setVisible(true)
              if (!(this.map.getLayers().getArray().some(layer => layer === this.dartGaugesLayer))) {
                this.map.addLayer(this.dartGaugesLayer);
              }
            } else {
              this.dartGaugesLayer.setVisible(false)
              // this.map.removeLayer(this.dartGaugesLayer)
            }
          }
        } catch (e) {
          console.error('error loading dart layer', e)
        }
      },
      buildGaugeLayer() {
        try {
          if (this.coastalGaugesLayer != null) {
            if (this.coastalLayerEnabled) {
              this.coastalGaugesLayer.setVisible(true)
              if (!(this.map.getLayers().getArray().some(layer => layer === this.coastalGaugesLayer))) {
                this.map.addLayer(this.coastalGaugesLayer);
              }

            } else {
              this.coastalGaugesLayer.setVisible(false)
              // this.map.removeLayer(this.coastalGaugesLayer)
            }
          }
        } catch (e) {
          console.error('error loading coastal layer', e)
        }
      },
      async buildSignalLayer() {
        try {
          if (this.signal && !this.signalLayer) {
            this.signalLayer = await getSignalLayer(this.signal)
          }
          if (this.signalLayer != null) {
            if (this.signalLayerEnabled) {
              this.signalLayer.setVisible(true)
              if (!(this.map.getLayers().getArray().some(layer => layer === this.signalLayer))) {
                this.map.addLayer(this.signalLayer);
              }
            } else if (this.signalLayer != null) {
              // this.map.removeLayer(this.signalLayer)
              this.signalLayer.setVisible(false)
            }
          } else {
            console.error('no signal layer')
          }
        } catch (e) {
          console.error('error loading earthquake layer', e)
        }
      },
      async buildIsoLayer() {
        try {
          if (this.signal && !this.isoLayer) {
            console.log('build iso layer for signal', this.signal.id)
            this.isoLayer = await getIsoLayer(this.signal)
          }
          if (this.isoLayer != null) {
            if (this.isoLayerEnabled) {
              this.isoLayer.setVisible(true)
              if (!(this.map.getLayers().getArray().some(layer => layer === this.isoLayer))) {
                this.map.addLayer(this.isoLayer);
              } else {
                this.isoLayer.setVisible(true)
              }
            } else {
              this.isoLayer.setVisible(false)
            }
          }
        } catch (e) {
          console.error('error loading iso layer', e)
        }
      },
      async buildWaveLayers() {
        try {
          console.log('trying load glowing overlay', this.overlayType, this.signal.id)
          if (this.overlayType === 'maxGlowEnabled') {
            if (!this.maxGlowingLayer) {
              this.maxGlowingLayer = await getGeotiffLayerAsync(this.getMaxGlowingUrl());
              // this.maxGlowingLayer = await getGeotiffLayerAsync('/map/pacific.tif');
            }
            if (this.map.getLayers().getArray().some(layer => layer === this.maxGlowingLayer)) {
              this.maxGlowingLayer.setVisible(true)
            } else {
              this.map.addLayer(this.maxGlowingLayer)
              this.maxGlowingLayer.setVisible(true)
            }

          } else {
            if (this.maxGlowingLayer) {
              this.maxGlowingLayer.setVisible(false)
            }
          }

          if (this.overlayType === 'minGlowEnabled') {
            if (!this.minGlowingLayer) {
              this.minGlowingLayer = await getGeotiffLayerAsync(this.getMinGlowingUrl());
              // this.minGlowingLayer = await getGeotiffLayerAsync('/map/pacific.tif');
            }
            if (this.map.getLayers().getArray().some(layer => layer === this.minGlowingLayer)) {
              this.minGlowingLayer.setVisible(true)
              // this.map.removeLayer(this.minGlowingLayer);
              // delete(this.minGlowingLayer)
            } else {
              this.map.addLayer(this.minGlowingLayer)
              this.minGlowingLayer.setVisible(true)
            }
          } else {
            if (this.minGlowingLayer) {
              this.minGlowingLayer.setVisible(false)
            }
          }
        } catch (e) {
          console.error('error loading min or max wave layer', e)
        }
      },
      getMaxGlowingUrl() {
        let msxGlowingUrl
        if (process.env.VUE_APP_API_URL) {
          msxGlowingUrl = `${process.env.VUE_APP_API_URL}glowings/${this.signal.id}/max_glowing`
        } else {
          msxGlowingUrl = `/api/v1/glowings/${this.signal.id}/max_glowing`
        }
        return msxGlowingUrl
      },
      getMinGlowingUrl() {
        let minGlowingUrl
        if (process.env.VUE_APP_API_URL) {
          minGlowingUrl = `${process.env.VUE_APP_API_URL}glowings/${this.signal.id}/min_glowing`
        } else {
          minGlowingUrl = `/api/v1/glowings/${this.signal.id}/min_glowing`
        }
        return minGlowingUrl
      },

      formLayers() {
        if (!this.map) return;

        this.loadingLayers = true
        this.buildBaseLayer()
        this.buildDartLayer()
        this.buildGaugeLayer()
        this.buildVirtualLayer()


        if (this.signal) {
          this.buildSignalLayer()
          this.buildIsoLayer()
          this.buildWaveLayers()
        }

        this.loadingLayers = false
      },
      displayFeatureInfo (pixel, target, that) {
        const feature = target.closest('.ol-control')
          ? undefined
          : this.map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
          });

        if (!feature) {
          const info = document.getElementById('info');
          info.style.visibility = 'hidden';
          that.protectedPoint = null
          that.selectedGauge = null
          that.selectedSignal = null
          // this.feature = ''
          currentFeature = null
          return
        }

        const info = document.getElementById('info');

        let name = feature.get('name') ? feature.get('name') : ''

        let mapElement = document.getElementById("map");
        that.feature = name
        info.style.left = pixel[0] + mapElement.offsetLeft + 'px';
        info.style.top = pixel[1] + mapElement.offsetTop + 'px';
        if (feature !== currentFeature) {
          info.style.visibility = 'visible';
        }
        if (feature.get('properties') && feature.get('properties').region) {
          // logger('protected point', feature.get('properties'))
          that.protectedPoint = feature.get('properties') && feature.get('properties')['region'] ? feature.get('properties') : {}
          that.selectedGauge = null
          that.selectedSignal = null
          this.selectedVirtual = null
        } else if (feature.get('properties') && feature.get('properties').code) {
          // logger('on gauge move', feature.get('properties'))
          that.selectedGauge = feature.get('properties') && feature.get('properties')['code'] ? feature.get('properties') : {}
          that.protectedPoint = null
          that.selectedSignal = null
          this.selectedVirtual = null
        } else if (feature.get('properties') && feature.get('properties').type === 'signal') {
          // logger('on gauge move', feature.get('properties'))
          that.selectedSignal = feature.get('properties') && feature.get('properties')['magnitude'] ? feature.get('properties') : {}
          that.protectedPoint = null
          that.selectedGauge = null
          this.selectedVirtual = null
        } else if (feature.get('properties') && feature.get('properties').type === 'virtual') {
          // logger('on gauge move', feature.get('properties'))
          that.selectedVirtual = feature.get('properties') && feature.get('properties')['magnitude'] ? feature.get('properties') : {}
          that.protectedPoint = null
          that.selectedGauge = null
          this.selectedSignal = null
        } else {
          // console.log('moved feature', feature.get('name'))
          that.protectedPoint = null
          that.selectedGauge = null
          that.selectedSignal = null
          this.selectedVirtual = null
        }

        currentFeature = feature;
      },
      unhide() {
        if (this.map) this.map.updateSize()
      },
      async initMap() {
        if (!this.getGauges) {
          await this.loadGauges();
        } else {
          this.buildGaugeLayers(this.getGauges)
          this.buildVirtualLayer()
        }
        logger('загрузка карты')
        this.map = null
        let that = this

        this.map = new Map({
          controls: [],
          // controls: defaultControls().extend([new FullScreen()]),
          // the map will be created using the 'map-root' ref
          target: this.$refs['map-root'],
          // the map view will initially show the whole world
          view: new View({
            projection: 'EPSG:4326',
            zoom: 2,
            minZoom: 2,
            maxZoom: 26,
            center: [ 180, 15],
            extent: [-180, -90, 180, 90],
            wrapX: true,
          }),
        })
        this.baseLayer = await getWorldLayer('/map/worldHigh.json')
        this.map.addLayer(this.baseLayer)

        // this.map.addControl(new MousePosition());
        this.map.addControl(new FullScreen());
        this.map.addControl(new Zoom());

        this.map.on('pointermove', function (evt) {
          if (evt.dragging) {
            const info = document.getElementById('info');
            info.style.visibility = 'hidden';
            currentFeature = undefined;
            return;
          }
          const pixel = that.map.getEventPixel(evt.originalEvent);
          // console.log('orig event', event.originalEvent)
          that.pointerCoords = `${evt.xy}`
          that.displayFeatureInfo(pixel, evt.originalEvent.target, that);
        });

        this.map.getTargetElement().addEventListener('pointerleave', function () {
          currentFeature = undefined;
          const info = document.getElementById('info');
          info.style.visibility = 'hidden';
        });


      },
      async init() {
        console.log('init')
        let that = this
        await this.initMap()
        this.basePointsLayer = getProtectedPointLayerFromLocations(await getLocations())
        // this.isoLayer = await getIsoLayer('/test/testiso.geojson')
        // this.map.addLayer(this.isoLayer);
        // this.map.addLayer(baseLayer);
        // this.map.addLayer(await getGeotiffLayerAsync('/test/case1.tif'));
        // this.map.addLayer(await getGeotiffLayerAsync('/test/nopoints.tif'));
        // this.map.addLayer(await getGeotiffLayerAsync('/test/max_glowing.tiff'));
        console.log('Карта добавлена на слой')
        this.formLayers()
        that.map.updateSize()
      },
    },
    mounted() {
      setTimeout(this.init(), 5000)

    }
  }
</script>

<style scoped>
  #viewDiv {
    width: 100%;
    height: 100%;
  }

  #info {
    border: 1px solid white;
    position: absolute;
    display: inline-block;
    height: auto;
    width: auto;
    z-index: 100;
    /*background-color: #333;*/
    /*color: #ffffff;*/
    text-align: center;
    border-radius: 4px;
    /*padding: 15px;*/
    left: calc(50% + 100px);

    transform: translateX(3%);
    pointer-events: none;
  }

  div.color-cell {
    width: 50px;
    height: 5px;
  }

  div.md-checkbox {
    margin: 0 !important;
  }

</style>
