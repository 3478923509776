// get virtual sources

import Point from 'ol/geom/Point.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import { pointOrangeStyle,} from "./customStyles";
import Feature from "ol/Feature";

const offset = -180  //global offset to draw in WCG_84_R projection (what is moved left with 180 degrees

const url = '/map/sources.json'

export async function getVirtualLayer() {

  let responce = await fetch(url)
  if (!responce) return;
  let virtualSources = await responce.json()
  if (virtualSources.code === 'SUCCESS') {
    virtualSources = virtualSources.payload
  } else {
    return null
  }

  let features = []

  console.log('virtual', virtualSources)
  if (virtualSources && virtualSources.length) {

    virtualSources.forEach(p => {
      let newLongitude = (p.longitude >= 0) ? p.longitude : (360 + p.longitude)

      let feature = new Feature({
        name: p.name,
        type: 'Feature',
        geometry: new Point([newLongitude + offset, p.latitude]),
        properties: {
          type: 'virtual',
          name: p.name,
          latitude: p.latitude,
          longitude: p.longitude,
          magnitude: p.magnitude
        },
      })
      features.push(feature)
    })

  }

  const vectorSource = new VectorSource({
    features: features
  });

  return new VectorLayer({
    visible: true,
    source: vectorSource,
    style: pointOrangeStyle,
    zIndex: 25,
    wrapX: true
  });
}

