import {Fill, Stroke, Style, Text} from 'ol/style.js';
import {RegularShape} from "ol/style";
import CircleStyle from "ol/style/Circle";

// let red = '#FF0000'

// const stroke = new Stroke({color: 'black', width: 2});
// const fill = new Fill({color: 'red'});
export const featureStyle1 = new Style({
  // fill: new Fill({color: '#D7D7D7'}),
  fill: new Fill({color: '#F4A460'}),
  stroke: new Stroke({color: 'black', width: 1}),
})


export function polygonGrayStyle() {
  return new Style({
    // fill: new Fill({color: '#D7D7D7'}),
    fill: new Fill({color: '#F4A460'}),
    stroke: new Stroke({color: 'black', width: 1}),
  })
}

export function pointYellowStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: 'yellow'}),
      stroke: new Stroke({color: 'black', width: 1}),
      points: 3,
      radius: 10,
      angle: 1.53
    }),
  })
}

export function pointOrangeStyle() {
  return new Style({
    image: new CircleStyle({
      radius: 6, // Радиус круга
      fill: new Fill({
        color: '#ff6544' // Красный цвет
      }),
      stroke: new Stroke({
        color: 'white', // Черный цвет обводки
        width: 3 // Ширина обводки
      })
    })
    // 'image': new RegularShape({
    //   fill: new Fill({color: '#ff8844'}),
    //   stroke: new Stroke({color: 'white', width: 1}),
    //   points: 4,
    //   radius: 10,
    //   angle: 0.78
    // }),
  })
}

export function pointBlueStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: '#040AF8'}),
      stroke: new Stroke({color: 'white', width: 1}),
      points: 4,
      radius: 12,
      angle: 0.78
    }),
  })
}

export function pointGreenStyle() {
  return new Style({
    'image': new RegularShape({
      fill: new Fill({color: '#008000'}),
      stroke: new Stroke({color: 'white', width: 1}),
      points: 4,
      radius: 12,
      angle: 0.78
    }),
  })
}

export function pointRedStyle() {
  return new Style({
    image: new CircleStyle({
      radius: 10, // Радиус круга
      fill: new Fill({
        color: 'red' // Красный цвет
      }),
      stroke: new Stroke({
        color: 'white', // Черный цвет обводки
        width: 5 // Ширина обводки
      })
    })
  });
}

const nir = ['band', 2];
// const blue = ['band', 3];
const red = ['band', 1];

const difference = ['-', nir, red];
const sum = ['+', nir, red];
// const difference = ['-', blue, red];
// const sum = ['+', blue, red];
const ndvi = ['/', difference, sum];

export const geotiffNVDI = {
  // color: ["color", 255, 0, 0, ["band", 2]],
  color: [  //rgb(94 150 120) //hsl(148 23 48) //#5E9678
    // 'arrays',
    'interpolate',
    ['linear'],
    ndvi,
    -0.2, // ndvi values <= -0.2 will get the color below
    // [191, 191, 191], // #BFBFBF
    [92, 92, 191], // #BFBFBF
    // [173, 216, 230],
    // [81, 18, 211],
    0.1, // ndvi values between -0.2 and 0 will get an interpolated color between the one above and the one below
    // [255, 255, 255],

    [173, 216, 230],
    // [81, 18, 211],
    0.2,
    [145, 191, 82],
    // [81, 18, 211],
    0.4,
    [79, 138, 46],
    // [81, 18, 211],
    0.6,
    [15, 84, 10],
    // [81, 18, 211],
  ],
}


let colorRed =  [254, 69, 0] //- Красный
let colorRed2 =  [255, 0, 0] //- Красный
// let colorOrange =  [255, 128, 0] //- Оранжевый
// let colorYellow = [255, 255, 0] //- Желтый
// let colorGreen =  [128, 255, 128] //- Зеленый

let colorPurple2 = [196, 68, 223]
let colorPurple = [180, 58, 223]
let colorBlue = [100, 50, 223]
let colorBlue1 = [110, 60, 223]
let colorBlue2 = [120, 120, 223]
let colorBlue3 = [150, 150, 223]
let colorBlue4 = [200, 200, 223]
// let color1 =  [0, 0, 0] //- черный
// let colorBackground =  [0, 128, 255] //- Сине-голубой
let colorBackground =  [171, 218, 223] //- Сине-голубой
// let color3 =  [0, 255, 255] //- Голубой
// let color3 =  [173, 216, 230] //- Голубой


const color01 = [108, 255, 200]
const color02 = [108, 255, 128]
const color03 =  [108, 255, 0]
const color04 =  [178, 255, 0]
const color05 =  [250, 255, 0]
const color06 =  [250, 224, 0]
const color07 =  [255, 153, 0]
const color08 =  [255, 118, 0]
const color09 =  [255, 84, 0]


export const geotiffCase = {
  color: [
    'case',
    ['<', ['band', 1], -1],
    colorPurple2,
    ['<', ['band', 1], -0.8],
    colorPurple,
    ['<', ['band', 1], -0.45],
    colorBlue,
    ['<', ['band', 1], -0.3],
    colorBlue1,
    ['<', ['band', 1], -0.15],
    colorBlue2,
    ['<', ['band', 1], -0.1],
    colorBlue3,
    ['<', ['band', 1], -0.05],
    colorBlue4,
    ['<', ['band', 1], 0.05],
    colorBackground,
    ['<', ['band', 1], 0.1],
    color01,
    ['<', ['band', 1], 0.2],
    color02,
    ['<', ['band', 1], 0.3],
    color03,
    ['<', ['band', 1], 0.4],
    color04,
    ['<', ['band', 1], 0.5],
    color05,
    ['<', ['band', 1], 0.6],
    color06,
    ['<', ['band', 1], 0.7],
    color07,
    ['<', ['band', 1], 0.8],
    color08,
    ['<', ['band', 1], 0.9],
    color09,
    ['>=', ['band', 1], 0.9],
    colorRed2,
    [0, 0, 0, 0.0]
  ],
}

export const geotiffRedArray = {
    color: [
      'array',
      ['*', ['band', 1], 100],
      0,
      0,
      ['*', ['band', 2], 0.7],
    ],
  }

export const geotiffLinear = {
  color:
    [
      'interpolate',
      ['exponential'],
      ['band', 1],
      -1,
      colorRed,
      1,
      colorPurple,
    ]
}
  //
  // [
  // 'interpolate',
  //   ['linear'],
  //   ['band', 1],
  //   -158,
  //   '#000000ff',
  //   4232,
  //   '#ffffffff',
  // ],

export function textStyle(feature) {
  console.log('textStyle')
  return [
    new Style({
      fill: new Fill({color: '#F4A460'}),
      stroke: new Stroke({color: 'black', width: 1}),
      text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        // get the text from the feature - `this` is ol.Feature
        // and show only under certain resolution
        text: feature.get('value')
      })
    })
  ];
}

export const labelStyle = new Style({
  text: new Text({
    font: '13px Calibri,sans-serif',
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 4,
    }),
  }),
});