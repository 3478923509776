import VectorLayer from "ol/layer/Vector";
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from "ol/source/Vector";
// import MultiPolygon from "ol/geom/MultiPolygon";
// import Polygon from "ol/geom/Polygon";
import {polygonGrayStyle} from "./customStyles";
import {updateFeatures} from "./mapUtil";

// import {MultiPolygon, Polygon} from 'ol/geom'

// const offset = -180
//
// function checkAntimeridian(feature) {
//
//   const coordinates = feature.getGeometry().getCoordinates();
//   // console.log('coordinates', JSON.stringify(coordinates))
//   let ultraPositive = false
//   let ultraNegative = false
//   coordinates.map(ring => {
//     if (Array.isArray(ring)) {
//       ring.some(arr => {
//         if (Array.isArray(arr) && Array.isArray(arr[0])) {
//           ultraPositive = ultraPositive || arr.some(point => point[0] > -21)
//           ultraNegative = ultraNegative || arr.some(point => point[0] < -21)
//         } else {
//           ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
//           ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
//         }
//         return ultraNegative
//       })
//     } else {
//       ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
//       ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
//     }
//   });
//   // console.log('checkAntimeridian', ultraNegative, feature.values_.name, '\n',)//JSON.stringify(feature),
//   return ultraNegative
// }
//
// function rewriteLongitudeMulti(multi, offset) {
//   // console.log('coordinates multi', JSON.stringify(coordinates))
//
//   const coordinates = multi.getCoordinates();
//
//   const updatedCoordinates = coordinates.map(ring => {
//     return ring.map(polygon => {
//
//       return polygon.map(point => {
//         // Изменяем долготу точки
//         let longitude = point[0]
//
//         point[0] = longitude + offset
//         return point;
//       })
//     });
//   });
//   multi.setCoordinates(updatedCoordinates);
//   return multi;
// }
//
// function rewriteLongitude(polygon, offset) {
//   const coordinates = polygon.getCoordinates();
//
//   const updatedCoordinates = coordinates.map(ring => {
//     return ring.map(point => {
//       // Изменяем долготу точки
//       let longitude = point[0]
//
//       point[0] = longitude + offset
//
//       return point;
//     });
//   });
//
//
//
//   // console.log('updatedCoordinates', JSON.stringify(updatedCoordinates))
//   polygon.setCoordinates(updatedCoordinates);
//   return polygon;
// }




export async function getWorldLayer(url) {
  let response = await fetch(url)
  let rawJson = await response.json()

  let features = new GeoJSON().readFeatures(rawJson)

  let response2 = await fetch('/map/special.json')
  let rawJson2 = await response2.json()

  let features2 = new GeoJSON().readFeatures(rawJson2)

  // features2 = features2.filter(f => that.checkAntimeridian(f))
  updateFeatures(features, true)
  updateFeatures(features2, false)

  const vectorSource = new VectorSource({
    features: [...features2, ...features]
  });
  // vectorSource.addFeatures(features2)

  const baseLayer = new VectorLayer({
    wrapx: false,
    source: vectorSource,
    // style: this.styleFunction,
    zIndex: 10,
    style: polygonGrayStyle,
    dataProjection: 'EPSG:4326', // Исходная проекция (долгота/широта)
    // featureProjection: 'EPSG:3857' // Целевая проекция (Web Mercator)              // wrapX: true
  });
  return baseLayer
}