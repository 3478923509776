import VectorLayer from "ol/layer/Vector";
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from "ol/source/Vector";
import {labelStyle,featureStyle1} from "./customStyles";
import {updateFeatures} from "./mapUtil";
import {getTimeGrid} from "../../api/time-grid-controller";

// export async function getIsoLayer(url) {
//   let response = await fetch(url)
//   let rawJson = await response.json()

export async function getIsoLayer(signal) {
  if (!signal || !signal.id) return null
  let response = await getTimeGrid(signal.id)
  console.log('timegrid response', response)
  if (response.success !== true) return null
  let rawJson = response
  let features = new GeoJSON().readFeatures(rawJson)

  features.forEach(f => {
      console.log('feature', f)
    }
  )

  console.log('updating features')
  updateFeatures(features, true)

  const vectorSource = new VectorSource({
    features: features
  });

  const style = [featureStyle1, labelStyle];

  const baseLayer = new VectorLayer({
    wrapx: true,
    wrapX: true,
    source: vectorSource,
    // style: this.styleFunction,
    zIndex: 70,
    style: function (feature) {
      let value = feature.get('value')
      let hours = Math.trunc(value / 3600)
      let minutes = Math.trunc(value / 60) - hours * 60

      // console.log('text', feature)
      labelStyle
      .getText()
      .setText([
        ` ${hours ? hours + 'ч ':''}${minutes}мин`,
        '',
        '\n',

      ]);
    return style;
  },
    dataProjection: 'EPSG:4326', // Исходная проекция (долгота/широта)
    // featureProjection: 'EPSG:3857' // Целевая проекция (Web Mercator)              // wrapX: true
  });
  return baseLayer
}