import GeoTIFF from 'ol/source/GeoTIFF.js';
import TileLayer from 'ol/layer/WebGLTile.js';
// import TileLayer from 'ol/layer/Tile.js';
// import Projection from "ol/proj/Projection";

import {geotiffCase} from "./customStyles";
import {getItem} from "../persistanceStorage";
// import {geotiffRedArray} from "./customStyles";
// import {geotiffLinear} from "./customStyles";
// import { geotiffNVDI} from "./customStyles";

export async function getGeotiffLayerAsync(url) {

  let myHeaders = new Headers({
    Authorization: 'Bearer ' + getItem('auth').accessToken,
    'Content-Type': 'application/x-www-form-urlencoded'
  })

  console.log('loading geotif from ', url)
  let response = await fetch(url, {
    headers: myHeaders,
    method: 'GET'
  })


  let source
  try {
    source = new GeoTIFF({
      sources: [
        {
          // url: url
          blob: await response.blob(),
        },
      ],
      // wrapX: true,
      normalize: false,
      // interpolate: true,
      projection: 'EPSG:4326'
    })
  } catch (e) {
    console.error('error loading geotiff', e)
  }

  // let view = await source.getView().extent

  // return source.getView()
  //   .then(view => {
  //     console.log('view', view.extent)
      // let extent = view.extent
      // view.setExtent([extent[2], extent[1], extent[0], extent[3]])

      // // let extent = view.extent
      // view.extent[0] = 119
      // view.extent[2] = 360-80
      // view.center[0] = view.extent[2] - view.extent[0]
      // console.log('view2', view.extent)


      return new TileLayer({

        visible: true,
        // opacity: 0.5,
        wrapX: true,
        source: source,
        extent: await source.getView().extent,
        // style: geotiffNVDI,
        style: geotiffCase,
        zIndex: 0,
        cacheSize: 10000
      })

      // // before rendering the layer, move it
      // tileLayer.on('precompose', function(event) {
      //   var ctx = event.context;
      //   // in case 1 pixel is not really 1 pixel, e.g iPhone
      //   var pixelRatio = event.frameState.pixelRatio;
      //   ctx.save();
      //   ctx.translate(pixelRatio * 50, pixelRatio * 50);
      // });

// // after rendering the layer, restore the canvas context,
// // so that continous rendering cycles do not stack
//
//       tileLayer.on('postcompose', function(event) {
//         var ctx = event.context;
//         ctx.restore();
//       });

      // return tileLayer
    // })
    // .catch(e => {
    //   console.log('getView error', e)
    //   return null
    // })

}

