import MultiPolygon from "ol/geom/MultiPolygon";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";

const offset = -180;


function checkAntimeridian(feature) {
 // console.log('checkAntimeridian')
  const coordinates = feature.getGeometry().getCoordinates();
  // console.log('coordinates', JSON.stringify(coordinates))
  let ultraPositive = false
  let ultraNegative = false
  if (feature.getGeometry() instanceof LineString) {
    coordinates.map(point => {
      ultraPositive = ultraPositive || point[0] > -21
      ultraNegative = ultraNegative || point[0] < -21
    })
  } else {
    coordinates.map(ring => {
      if (Array.isArray(ring)) {
        ring.some(arr => {
          if (Array.isArray(arr) && Array.isArray(arr[0])) {
            ultraPositive = ultraPositive || arr.some(point => point[0] > -21)
            ultraNegative = ultraNegative || arr.some(point => point[0] < -21)
          } else {
            ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
            ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
          }
          return ultraNegative
        })
      } else {
        ultraPositive = ultraPositive || ring.some(point => point[0] > -21)
        ultraNegative = ultraNegative || ring.some(point => point[0] < -21)
      }
    });
  }
  // console.log('checkAntimeridian', ultraNegative, feature.values_.name, '\n',)//JSON.stringify(feature),
  return ultraNegative
}


function rewriteLongitudeMulti(multi, offset) {
  // console.log('coordinates multi', JSON.stringify(coordinates))

  const coordinates = multi.getCoordinates();

  const updatedCoordinates = coordinates.map(ring => {
    return ring.map(polygon => {

      return polygon.map(point => {
        // Изменяем долготу точки
        let longitude = point[0]

        point[0] = longitude + offset
        return point;
      })
    });
  });
  multi.setCoordinates(updatedCoordinates);
  return multi;
}

function rewriteLongitude(polygon, offset) {
  const coordinates = polygon.getCoordinates();

  const updatedCoordinates = coordinates.map(ring => {
    return ring.map(point => {
      // Изменяем долготу точки
      let longitude = point[0]

      point[0] = longitude + offset

      return point;
    });
  });



  // console.log('updatedCoordinates', JSON.stringify(updatedCoordinates))
  polygon.setCoordinates(updatedCoordinates);
  return polygon;
}

function rewriteLongitudeLine(line, offset) {
  console.log('rewriteLongitudeLine')
  const coordinates = line.getCoordinates();

  const updatedCoordinates = coordinates.map(point => {
    // Изменяем долготу точки
    let longitude = point[0]

    point[0] = longitude + offset

    return point;
  });

  // console.log('updatedCoordinates', JSON.stringify(updatedCoordinates))
  line.setCoordinates(updatedCoordinates);
  return line;
}

export function updateFeatures(features, testAntimeridian) {

  features.forEach(feature => {
    let negative = testAntimeridian ? checkAntimeridian(feature) : false
    if (feature.getGeometry() instanceof MultiPolygon) {
      const polygon = feature.getGeometry();
      const updatedPolygon = rewriteLongitudeMulti(polygon, negative ? -offset : offset);
      feature.setGeometry(updatedPolygon);
    } else if (feature.getGeometry() instanceof Polygon) {
      const polygon = feature.getGeometry();
      const updatedPolygon = rewriteLongitude(polygon, negative ? -offset : offset);
      feature.setGeometry(updatedPolygon);
    } else if (feature.getGeometry() instanceof LineString) {
      const lineString = feature.getGeometry();
      const updatedLineString = rewriteLongitudeLine(lineString, negative ? -offset : offset);
      feature.setGeometry(updatedLineString);
    }

    // console.log('feature', feature.values_.name, JSON.stringify(feature.getGeometry().getCoordinates()))
  });
}



