<template>
  <div class="protected-popup">
    <div class="protected-popup-row">
      <div>Тип</div>
      <div>{{protectedPoint.region}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Защищаемый пункт</div>
      <div>{{protectedPoint.name}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Широта</div>
      <div>{{protectedPoint.latitude}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Долгота</div>
      <div>{{protectedPoint.longitude}}</div>
    </div>
    <div class=""  style="text-align: center; color: white;">
      Расчет
    </div>
    <div class="protected-popup-row">
      <div>Смещение от времени сигнала (с)</div>
      <div>{{protectedPoint.time ? protectedPoint.time : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Время прихода</div>
      <div>{{protectedPoint.time ? getCalcTime : '-'}}</div>
    </div>
    <!--<div class="protected-popup-row">-->
      <!--<div>Высота (м)</div>-->
      <!--<div>{{protectedPoint.waveHeight ? protectedPoint.waveHeight.toFixed(2) : '-'}}</div>-->
    <!--</div>-->
    <div class="" style="text-align: center; color: white;">
      Виртуальный источник:
    </div>
    <div class="" style="text-align: center; color: white;">
      Первая волна:
    </div>
    <div class="protected-popup-row">
      <div>Смещение от времени сигнала (с)</div>
      <div>{{protectedPoint.waveTime ? protectedPoint.waveTime : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Время добегания</div>
      <div>{{protectedPoint.waveTime ? getWaveTime(protectedPoint.waveTime) : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>высота</div>
      <div>{{protectedPoint.waveHeight ? protectedPoint.waveHeight.toFixed(2) : '-'}}</div>
    </div>
    <div class="" style="text-align: center; color: white;">
      Максимальная волна:
    </div>
    <div class="protected-popup-row">
      <div>Смещение от времени сигнала (с)</div>
      <div>{{protectedPoint.maxWaveTime ? protectedPoint.maxWaveTime : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Время добегания</div>
      <div>{{protectedPoint.maxWaveTime ? getWaveTime(protectedPoint.maxWaveTime) : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Высота (м)</div>
      <div>{{protectedPoint.maxWaveHeight ? protectedPoint.maxWaveHeight : '-'}}</div>
    </div>
    <div class="" style="text-align: center; color: white;">
      Минимальная волна:
    </div>
    <div class="protected-popup-row">
      <div>Смещение от времени сигнала (с)</div>
      <div>{{protectedPoint.minWaveTime ? protectedPoint.minWaveTime : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Время добегания</div>
      <div>{{protectedPoint.minWaveTime ? getWaveTime(protectedPoint.minWaveTime) : '-'}}</div>
    </div>
    <div class="protected-popup-row">
      <div>Высота (м)</div>
      <div>{{protectedPoint.minWaveHeight ? protectedPoint.minWaveHeight : '-'}}</div>
    </div>
  </div>
</template>

<script>
  import {getDateTime} from "../utils/time";

  export default {
    name: 'protectedPointView',
    props: {
      signal: Object,
      protectedPoint: Object
    },
    computed: {

      getCalcTime() {
        console.log('protected point popup count time')
        if (!this.protectedPoint || !this.signal) return '-'
        let offset = this.protectedPoint.time
        if (offset !== 0 && !offset) return '';

        let date = new Date(this.signal.earthquakeDate)
        // let translate = getDateTime(date, true)
        if (offset) {
          date.setSeconds(date.getSeconds() + +offset)
        }
        return getDateTime(date, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
      }
    },
    methods: {
      getWaveTime(offset) {
        console.log('protected point popup count time')
        if (!this.protectedPoint || !this.signal) return '-'
        if (offset !== 0 && !offset) return '';

        let date = new Date(this.signal.earthquakeDate)
        // let translate = getDateTime(date, true)
        if (offset) {
          date.setSeconds(date.getSeconds() + +offset)
        }
        return getDateTime(date, false) + ' ВСВ'// + ' (' + translate + ' + '+ offset + ')'
      },
    }
  }
</script>

<style scoped>
  .protected-popup {
    background-color: green;
    padding: 15px;
    gap: 5px;

  }

  .protected-popup-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: white;
    font-size: 18px;
  }
</style>